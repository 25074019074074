import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b28a80fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-block" }
const _hoisted_2 = { class: "block-title" }
const _hoisted_3 = { class: "block-cont" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}